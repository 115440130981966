@tailwind base;
@tailwind components;
@tailwind utilities;




* {
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s ease-in;
}


.opacity-m {
animation: opacity 1s ease;
}

@keyframes opacity {
from {
    opacity: 0;
  }

  to{
    opacity: 1;
  }
}
.logo {
  font-family: 'Nunito', sans-serif;
}

.move-hor {
  animation: move-hor 3s infinite linear;
}

@keyframes move-hor {
  0% {
    left: 0px;
  }

  50% {
    left: 90px
  }

  100% {
    left: 0px
  }
}

.rotate {
  animation: rotation 4s infinite;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(359deg);
  }
}

.bounce-animation {
  animation: bounce 4s infinite
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-50px);
  }

  60% {
    transform: translateY(-25px);
  }
}

.bg-1 {
  background: url('/src/assets/background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-2 {
  background: url('/src/assets/bg-2.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-2-h{
  background: url('/src/assets/bg-2.svg');
  background-position:top
  
}


