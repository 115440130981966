.text-shadow{
    /* text-shadow: 1px 1px #78403f; */
    text-shadow: 1px 1.5px #898888;
}
.text-shadow-sm{
    /* text-shadow: 1px 1px #78403f; */
    text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
    /* text-shadow: 1px 0.5px 2px #6b6b6b65 ; */
}
.text-shadow-m{
    text-shadow: 1px 1px #0b3f46;
    /* text-shadow: 1px 2px #fff; */
}